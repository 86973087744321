import { render, staticRenderFns } from "./ProductVideo.vue?vue&type=template&id=3497fa36&scoped=true"
import script from "./ProductVideo.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductVideo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProductVideo.vue?vue&type=style&index=0&id=3497fa36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3497fa36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiIcon.vue').default})
